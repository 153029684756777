import React from 'react';
import logo from './logo2.svg';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            Welcome to <strong>Night Club Order</strong> and by a drink.
        </p>
        <a
          className="App-link"
          href="https://slc.org.ua"
          target="_blank"
          rel="noopener noreferrer"
        >
          SLC
        </a>
      </header>
        <AddToHomeScreen
        appId='Add-to-Homescreen React Modified Behavior Example'
        startAutomatically={ true }
        startDelay={ 0 }
        lifespan={ 300 }
        skipFirstVisit={ false }
        displayPace={ 0 }
        customPromptContent={ {
            title: 'Do you want to install Night Club Order on your homescreen?',
            cancelMsg: 'No',
            installMsg: 'Yes, sure!',
            guidanceCancelMsg: 'Dismiss',
            src: 'images/logo2_96.png'
        } }
        />
    </div>
  );
}

export default App;
